import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'
import schttp from 'public/src/services/schttp'

const isBotBff = () => {
  return isSwitchBffApiVersion(['robot_common_v1'])?.['robot_common_v1']
}


// 获取未读消息数
export const getUnreadCount = async ({ params }) => {
  if (isBotBff()) {
    const res = await schttp({ 
      url: '/user/cschat/get_unread_count',
      method: 'POST',
      data: params,
      useBffApi: true,
    })
    const { code, info } = res || {}
    const { tk_unread_count = 0, total_unread_count = 0 } = info || {}
    return {
      code,
      info: {
        tk_unread_count,
        chat_unread_count: total_unread_count
      }
    }
  } else {
    return schttp({ 
      url: '/api/robot/common/unReadCount/query',
      params
    })
  }
}

// 获取渠道入口开关
export const getBotSwitch = () => {
  if (isBotBff()) {
    return schttp({ 
      url: '/ticket/robot_customer_chan',
      useBffApi: true,
    })
  } else {
    return schttp({ 
      url: '/api/robot/channel/init/query',
    })
  }
}
