<template>
  <div 
    v-if="!isShowSaver" 
    class="prime-pannel_wrapper fsp-element" 
    @click.stop="jumpTo"
  >
    <div
      class="prime-pannel"
      :style="{
        backgroundImage: `url(${
          IMG_LINK[
            GB_cssRight
              ? 'pannel-bg-no_prime-ar'
              : isPrime &&
                paidDetail.billno_enjoy_privilege_info.req_currency_price > 0
                ? 'pannel-bg'
                : 'pannel-bg-no_prime'
          ]
        })`,
      }"
    >
      <div class="pannel-main">
        <div class="prime-hd">
          <div class="prime-hd__left">
            <img
              class="prime-hd_img"
              :src="primeLogo"
            />
            <div
              v-if="isExpireStatus"
              class="prime-hd__expire"
            >
              {{ language.SHEIN_KEY_PC_24495 }}
            </div>
            <div
              v-if="isTrialing && isPrime"
              class="prime-hd__trial"
            >
              {{ language.SHEIN_KEY_PC_24870 }}
            </div>
          </div>
          <div
            class="prime-hd_right"
            @click.stop="jumpTo"
          >
            <PrimePannelBtn
              :current-user-prime-status="currentUserPrimeStatus"
              :discount-price="discountPrice"
              :language="language"
              :buy-when="buyWhen"
              :has-unpaid-order="hasUnpaidOrder"
              :get-expose-params="getExposeParams"
              :panel-info-map="panelInfoMap"
            />
          </div>
        </div>

        <PrimePannelContent 
          :language="language"
          :current-user-prime-status="currentUserPrimeStatus" 
          :discount-price="discountPrice"
          :total-discount-price-with-symbol="totalDiscountPriceWithSymbol"
          :reduce-price-with-symbol="reducePriceWithSymbol"
          :special-price-with-symbol="specialPriceWithSymbol"
          :arrival-price-with-symbol="arrivalPriceWithSymbol"
          :latest30-days-payed="latest30DaysPayed"
          :current-user-saved-info="currentUserSavedInfo" 
          :coupon-value-with-symbol="couponValueWithSymbol"
          :discount-price-with-percent="discountPrice_WithPercent"
          :cur-user-coupon-count="curUserCouponCount"
          :current-user-saved-money="currentUserSavedMoney"
          :current-user-saved-money-with-symbol="currentUserSavedMoney_withSymbol"
          :probationary-member-buy30="probationaryMemberBuy30"
          :history-saved-money="historySavedMoney"
          :history-saved-money-with-symbol="historySavedMoney_withSymbol"
          :get-span-txt="getSpanTxt"
          :get-span-txt-big-size="getSpanTxtBigSize"
          :column-number="1"
          :buy-when="buyWhen"
          :main-text="panelInfoMap?.mainText || ''"
        />

        <!-- 权益列表 -->
        <div
          v-if="isShowRightConfigList"
          class="prime-content right-config-list"
        >
          <template v-for="(item, index) in rightConfigList">
            <div
              v-if="index < 3"
              :key="index"
              class="equity-item"
              :class="{ 'more-item': rightConfigList.length >= 3 }"
            >
              <img :src="item.right_type_icon" />
              <span v-if="rightConfigList.length == 1">
                {{ language.SHEIN_KEY_PC_22939 }}
              </span>
              <span
                v-if="rightConfigList.length > 1"
                v-html="item.right_type_name"
              ></span>
            </div>
            <div
              v-if="rightConfigList.length < 3"
              :key="index"
              class="equity-item_line"
            ></div>
          </template>
          <template v-if="rightConfigList.length > 3">
            <img :src="IMG_LINK['fengge']" />
            <div class="more-equity">
              {{ language.SHEIN_KEY_PC_22940 }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>

  <!-- 一排二 -->
  <div
    v-if="isShowSaver"
    class="prime-pannel2 fsp-element"
    :class="{'prime-pannel2__ar':GB_cssRight}"
    @click.stop="jumpTo"
  >
    <div class="prime-pannel2__header">
      <div class="prime-pannel2__logo-wrap">
        <img
          class="logo"
          :src="primeLogo"
        />
        <div
          v-if="isExpireStatus"
          class="status"
        >
          {{ language.SHEIN_KEY_PC_24495 }}
        </div>
      </div>
      <!--  v-if="currentPrimeRightConfig.length>0" -->
      <div class="prime-pannel2__icons">
        <div
          v-for="(item, index) in currentPrimeRightConfig"
          :key="index"
          class="prime-pannel2__icon-wrap"
        >
          <img :src="cutImg(item.right_type_icon)" />
        </div>
      </div>
    </div>
    <PrimePannelContent 
      :language="language"
      :current-user-prime-status="currentUserPrimeStatus" 
      :discount-price="discountPrice"
      :total-discount-price-with-symbol="totalDiscountPriceWithSymbol"
      :reduce-price-with-symbol="reducePriceWithSymbol"
      :special-price-with-symbol="specialPriceWithSymbol"
      :arrival-price-with-symbol="arrivalPriceWithSymbol"
      :latest30-days-payed="latest30DaysPayed"
      :current-user-saved-info="currentUserSavedInfo" 
      :coupon-value-with-symbol="couponValueWithSymbol"
      :discount-price-with-percent="discountPrice_WithPercent"
      :cur-user-coupon-count="curUserCouponCount"
      :current-user-saved-money="currentUserSavedMoney"
      :current-user-saved-money-with-symbol="currentUserSavedMoney_withSymbol"
      :probationary-member-buy30="probationaryMemberBuy30"
      :history-saved-money="historySavedMoney"
      :history-saved-money-with-symbol="historySavedMoney_withSymbol"
      :get-span-txt="getSpanTxt"
      :get-span-txt-big-size="getSpanTxtBigSize"
      :buy-when="buyWhen"
      :cur-discount-value-text="curDiscountValueText"
      :has-unpaid-order="hasUnpaidOrder"
      :main-text="panelInfoMap?.mainText || ''"
    />
    <div class="prime-pannel2__footer">
      <PrimePannelBtn
        :current-user-prime-status="currentUserPrimeStatus"
        :discount-price="discountPrice"
        :language="language"
        :buy-when="buyWhen"
        :has-unpaid-order="hasUnpaidOrder"
        :get-expose-params="getExposeParams"
        :panel-info-map="panelInfoMap"
      />
    </div>
  </div>

  <!-- 回本动效弹窗 -->
  <ClientOnly>
    <div @click.stop="">
      <pay-back-dialog
        from="me"
        :prime-info="paidDetail"
        :user-info="userInfo"
        :language="language"
        :buy-when="buyWhen"
        :is-show="isShowBackDialog"
        :is-trialing="isTrialing"
        :is-discount-by-renew="isDiscountByRenew"
        @close-handle="renewHandle"
      />
    </div>
  </ClientOnly>

  <!-- 续费弹窗 -->
  <ClientOnly>
    <div @click.stop="">
      <renew-dialog
        :is-show="isShowRenewDialog"
        :config-data="renewDialogConfig"
      />
    </div>
  </ClientOnly>
</template>

<script>
import { template, removeLocalStorage, getLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-22-8' })
import { postCheckFreeTrial } from 'public/src/pages/user_prime_new/fetch.js'
import PrimePannelContent from './primePannelContent.vue'
import PrimePannelBtn from './primePannelBtn.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { defineAsyncComponent } from 'vue'

/*
  会员状态 [1-4]
    1. 未购买 / 退订中   (front_status != 1 || front_status == null) && buy_when == 1
    2. 生效中-非临期      front_status == 1 && buy_when != 3
    3. 生效中-临期 3.1 适用中(同3)      front_status == 1 && buy_when == 3 && product_info.product_type == 0     3.1  front_status == 1 && product_info.product_type != 0
    4. 逾期/ 已退订  (front_status != 1 || front_status == null) && buy_when != 1

  优惠金额
    A: 大于0
    B: 等于0

  按钮样式: (里面文案) 
    1. Join now  // SHEIN_KEY_PC_27007
    2. Details   // SHEIN_KEY_PC_27020
    3. Renew Now  // SHEIN_KEY_PC_27014
    3.1  Renew Now
    4. Renew Now 

  按钮上气泡: 
    1. 优惠金额>0时显示气泡 : Special Offer  // SHEIN_KEY_PC_27003
    2. 不显示
    3. 优惠金额>0时显示气泡 : Special Offer
    3.1 同3
    4. 优惠金额>0 显示气泡Special Offer 

  文案: 
    // 省额 discount_summary_info.discount_price  Number  currentUserSavedMoney
    // 优惠金额  product_price_info.price_discount_rate  >0 就是有优惠     product_price_info       discountPrice
    // 近30天支付: 取    /v2/User/paid/calculateRecentOrderCanSavingAmount  total_order_amount / total_order_amount_with_symbol    latest30DaysPayed
    1.
      优惠金额>0: 
          文案1:  Exclusive Deal: {0}% OFF! 100k + items & {1}X FREE shipping.  //  SHEIN_KEY_PC_27006
          
          文案2: Join with {0}% OFF special discount.  // SHEIN_KEY_PC_27004  {0} discountPrice

      优惠金额=0且近30天支付>0:
          文案1:  --- Exclusive Deal: 5% OFF! 100k + items & {1}X FREE shipping.  // SHEIN_KEY_PC_27006
          
          文案2: Your missed savings without joining: $X:    //  SHEIN_KEY_PC_27005  this.currentUserSavedInfo.total_saving_amount_with_symbol

      优惠金额=0：
          文案1: Exclusive Deal: {0}% OFF! 100k + items & {1}X FREE shipping.  // SHEIN_KEY_PC_27006
          
          文案2: -

    2. 
      （仅中间一段有文案）
      无省额: Enjoy {0}% OFF 100k items & shipping discount.   //  SHEIN_KEY_PC_27019
      有省额: Total savings: {0}.      // SHEIN_KEY_PC_27010
    
    3.  3.1 同3
      （仅中间一段有文案）
      无省额 && 优惠金额>0：Special offer on extending membership: {X}% off coupon.    // SHEIN_KEY_PC_27017
      无省额 && 优惠金额=0：Your membership is expiring soon. Renew to retain exclusive benefit(s).   // SHEIN_KEY_PC_27015 + SHEIN_KEY_PC_27018

      （两段文案）
      有省额 && 优惠金额>0：
        文案1：Total savings: ${XX}. // SHEIN_KEY_PC_27010
        文案2：Special offer on extending membership: {X}% off coupon.  // SHEIN_KEY_PC_27017
      有省额 && 优惠金额=0：
        文案1：Total savings: ${XX}. // SHEIN_KEY_PC_27010
        文案2：Renew to retain exclusive benefit(s).    //  SHEIN_KEY_PC_27018
    
    4. 
      历史无省额（仅中间一段有文案）
      历史无省额 && 优惠金额>0：Renew with {0}% OFF special discount    // SHEIN_KEY_PC_27008
      历史无省额 && 优惠金额=0：Renew to reactivate 100k items 5% off & {X}× free shipping.  // SHEIN_KEY_PC_27009
      
      历史有省额（两段文案）
      历史有省额 && 优惠金额>0：
        文案1：Total savings: ${XX}. // SHEIN_KEY_PC_27010
        文案2： Renew with {0}% OFF special discount  // SHEIN_KEY_PC_27008
      历史有省额 && 优惠金额=0：
        文案1：Total savings: ${XX}.  // SHEIN_KEY_PC_27010
        文案2：Renew to reactivate exclusive benefit(s).   // SHEIN_KEY_PC_27013

*/ 

export default {
  name: 'PrimePannel',
  inject: ['userInfo', 'apolloConfig', 'abtInfo', 'commonInfo', 'cutImg'],
  components: { 
    payBackDialog: defineAsyncComponent(()=>import(/* webpackChunkName: "payBackDialog" */'public/src/pages/user_prime_new/components/payBackDialog/index.vue')),
    RenewDialog: defineAsyncComponent(()=>import(/* webpackChunkName: "RenewDialog" */'./renewDialog/index.vue')),
    PrimePannelContent,
    PrimePannelBtn,
    ClientOnly
  },
  props: {
    isShowSaver: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => {},
    },
    primeInfoThreeForOne: { // 三合一，产品包已过滤「试用包 & abt控制的自动续费包」
      type: Object,
      default: () => {},
    },
    savedInfo: { // 非付费会员试算历史订单可省金额
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { langPath, IMG_LINK, GB_cssRight, PUBLIC_CDN, SiteUID } = this.commonInfo || gbCommonInfo
    const { RENEW_DIALOG_DELAY } = this.apolloConfig || gbCommonInfo
    return {
      RENEW_DIALOG_DELAY,
      SiteUID,
      langPath,
      IMG_LINK,
      PUBLIC_CDN,
      GB_cssRight,
      trialInfo: {},
      isTrialPrime: false, // 是否可试用
      isShowBackDialog: false,
      sheinclubDiscountType: this.apolloConfig['SHEINCLUB_DISCOUNT_TYPE'],
      currentUserSavedInfo: this.savedInfo,
      curUserCouponCount: 0, // 免邮券张数
      couponValueWithSymbol: '', // 免邮券价值

      isPrime: Boolean(this.paidDetail?.product_info),
      buyWhen: this.primeInfoThreeForOne?.buy_when, // 1-首次购买，2-过期后购买，3-续费购买，4-退订购买
      productInfoList: this.primeInfoThreeForOne?.product_info_list || [],
      paidDetail: this.primeInfoThreeForOne?.paid_detail || {},
      sheinClubFieldsMap: this.primeInfoThreeForOne?.sheinClubFieldsMap || null,
      primeLogo: '//img.ltwebstatic.com/images3_ccc/2024/05/30/c1/1717050669a2edef94954af1bd6e16e2f914672796.webp'
    }
  },
  computed: {
    curDiscountValue() {
      const discountInfo = this.commandProduct?.right_config_list?.find(item => item.right_type_code === 'EXCLUSIVE_DISCOUNTS')
      return 100 - (discountInfo?.exclusive_price_discount_rate || 0.95) * 100 // 兜底5%折扣，所以exclusive_price_discount_rate兜底0.95
    },
    reducePriceWithSymbol() {
      return this.commandProduct?.product_price_info?.reduce_price_with_symbol
    },
    currentUserPrimeStatus(){
      // 1. 未购买 / 退订中   (front_status != 1 || front_status == null) && buy_when == 1
      // 2. 生效中-非临期      front_status == 1 && buy_when != 3
      // 3. 生效中-临期 3.1 适用中(同3)      front_status == 1 && buy_when == 3 && product_info.product_type == 0     3.1  front_status == 1 && product_info.product_type != 0
      // 4. 逾期/ 已退订  (front_status != 1 || front_status == null) && buy_when != 1
      const frontStatus = this.paidDetail.product_info?.front_status
      const productType = this.paidDetail.product_info?.product_type
      if((frontStatus != 1 || frontStatus == null) && this.buyWhen == 1 || (!frontStatus && !this.buyWhen )){
        return 1
      }
      // 生效中&不支持续费，约旦站点不支持续费
      // TODO:三合一接口未对禁用国家进行buy_when字段特殊处理来组织续费，需要前端根据reason来进行兜底判断
      // reason 5 - 所在地不支持付费会员, 添加reason限制使其不进入续费状态
      if(frontStatus == 1 && (this.buyWhen != 3 || this.primeInfoThreeForOne?.reason === 5)){
        return 2
      }
      if((frontStatus == 1 && this.buyWhen == 3 && productType == 0 && this.primeInfoThreeForOne?.reason !== 5) || (frontStatus == 1 && productType != 0)){
        return 3
      }
      if((frontStatus != 1 || frontStatus == null) && this.buyWhen != 1){
        return 4
      }
      return 1
    },
    curDiscountValueText(){
      return this.curDiscountValue + '%'
    },
    currentUserSavedMoney_withSymbol(){
      // 使用中的会员的省额
      return this.paidDetail.billno_enjoy_privilege_info?.req_currency_price_with_symbol
    },
    historySavedMoney(){
      // 历史省额: 已过期的会员就叫历史省额
      return this.paidDetail.discount_summary_info?.discount_price || 0
    },
    historySavedMoney_withSymbol(){
      // 历史省额: 已过期的会员就叫历史省额
      return this.paidDetail.discount_summary_info?.discount_price_with_symbol
    },
    specialPriceWithSymbol() {
      // 优惠价
      return this.commandProduct?.product_price_info?.special_price_with_symbol
    },
    arrivalPriceWithSymbol() {
      return this.commandProduct?.product_price_info?.arrival_price_with_symbol
    },
    totalDiscountPriceWithSymbol() {
      const productInfoList = this.productInfoList || []
      const curReducePriceWithSymbol = (this.productInfoList?.find(item => item.is_recommend == 1) 
      || productInfoList[0])?.product_price_info?.total_discount_price_with_symbol
      return this.commandProduct?.product_price_info?.total_discount_price_with_symbol || curReducePriceWithSymbol
    },
    latest30DaysPayed(){
      // 最近30天支付
      // return 0 // mock 0
      return this.currentUserSavedInfo?.total_order_amount
    },
    // 是否试用
    isTrialing () {
      return this.paidDetail?.product_info?.product_type == 1
    },
    isSaveAmount () {
      return this.paidDetail?.billno_enjoy_privilege_info || {}
    },
    isOverZeroAmount () {
      return this.isSaveAmount?.req_currency_price > 0
    },
    // 是否过期续费购买
    isExpireStatus () {
      return this.buyWhen === 2
    },
    // 是否有续费优惠折扣
    isDiscountByRenew () {
      if (![2, 3].includes(this.buyWhen)) return 0
      return this.commandProduct?.product_price_info?.price_discount_rate || 0
    },
    // 是否展示权益信息
    isShowRightConfigList () {
      if (!this.rightConfigList.length) return false
      if (!this.isPrime) return true
      if (this.buyWhen == 3 && !this.isOverZeroAmount) return true
      return false
    },
    commandProduct () {
      // 展示使用的产品包
      // 会员身份 - 付费详情产品包
      // 非会员身份 - 产品包列表第一个（三合一数据）
      return this.isPrime ? this.paidDetail.product_info : this.productInfoList[0]
    },
    // 权益列表
    rightConfigList () {
      if (this.isPrime) {
        return this.paidDetail?.product_info?.right_config_list || []
      }
      return this.commandProduct?.right_config_list || []
    },
    discountPrice(){
      // 优惠金额
      return this.productInfoList?.[0]?.product_price_info?.price_discount_rate || 0
    },
    discountPrice_WithPercent(){
      // 购买优惠
      return this.discountPrice > 0 ? (this.discountPrice * 1000 / 10) + '%' : this.curDiscountValueText
    },
    currentUserSavedMoney(){
      // 使用中的会员的省额
      return this.paidDetail.billno_enjoy_privilege_info?.price_usd || 0
    },
    probationaryMemberBuy30() {
      // 临期天数<= 30天
      return  this.paidDetail?.product_info?.end_time -  Math.round(new Date().getTime() / 1000) <= 30 * 24 * 60 * 60
    },
    currentPrimeRightConfig() {
      //  生效会员权益列表
      if(this.currentUserPrimeStatus == 2 || this.currentUserPrimeStatus == 3){
        return this.paidDetail.product_info?.right_config_list || []
      }
      if(this.currentUserPrimeStatus == 1 || this.currentUserPrimeStatus == 4){
        return this.productInfoList?.[0]?.right_config_list
      }
      return []
    },
    hasUnpaidOrder() {
      return this.primeInfoThreeForOne?.unpaid_link_billno || ''
    },
    /**
     * @desc 2023-12-18 埋点新增buy_content字段
     * @desc 默认0(不展示按钮 或者 details)、1-join(未购买 / 退订中, 逾期/ 已退订)、2-续费(2. 生效中-非临期 3. 生效中-临期)、3-支付(hasUnpaidOrder)
     * @return buy_content
     */
    getButtonContent() {
      let buttonContent = 0
      if (this.currentUserPrimeStatus === 1) {
        buttonContent = 1
      }
      if (this.currentUserPrimeStatus === 4) {
        buttonContent = 1
        if (!!this.buyWhen) {
          buttonContent = 2
        }
      }
      if (this.currentUserPrimeStatus === 3) {
        buttonContent = 2
      }
      if (this.hasUnpaidOrder) {
        buttonContent = 3
      }
      return buttonContent
    },
    isShowRenewDialog() {
      const result = this.handleIsShowRenewDialog()
      return result
    },
    renewDialogConfig() {
      const [endTime, currentTime] = [
        this.paidDetail?.product_info?.total_end_time || 0,
        Date.parse(new Date()) / 1000
      ]
      // 回本倍数
      const roi = this.paidDetail?.billno_enjoy_privilege_info?.roi ?? '-'
      // prime_tp：产品类型，0-正式版、1-体验版
      const prime_tp = this.paidDetail?.product_info?.product_type ?? '-'
      // 付费会员总到期时间
      const total_end_time = this.paidDetail?.product_info?.total_end_time ?? '-'

      // 有会费折扣或会费券
      const hasTotalDiscountPrice = this.commandProduct?.product_price_info?.total_discount_price > 0
      // 是否过期
      const isExpired = endTime - currentTime < 0
      let tempObj = {
        userInfo: this.userInfo,
        seconds: Math.abs(endTime - currentTime), // 倒计时秒数 大于3天（259200秒）不展示倒计时
        isCoupons: hasTotalDiscountPrice,
        title: this.language[isExpired ? 'SHEIN_KEY_PC_29379' : 'SHEIN_KEY_PC_29378'],
        prime_promotion: this.isDiscountByRenew ? 1 : 0, // 组件曝光埋点需要
        roi, // 组件曝光埋点需要
        prime_tp,
        total_end_time,
        title2: this.language.SHEIN_KEY_PC_29399, // 有倒计时的情况下增加一个标题
        ...this.getHasSaveTxt(hasTotalDiscountPrice, roi, isExpired),
        ...this.getContentAndBtnTxt(hasTotalDiscountPrice)
      }
      return tempObj
    },
    // 获取面板信息
    panelInfoMap(){
      let panelInfo = {}
      if (this.sheinClubFieldsMap) { // shein club配置 pageId=1460912802
        panelInfo.mainText = this.sheinClubFieldsMap.main_text
        panelInfo.btn = this.sheinClubFieldsMap.Button_text
        panelInfo.specialOffer =  this.sheinClubFieldsMap.bubble_text
      }
      return panelInfo
    },
  },
  async created() {
    this.pageDataInit()
  },
  mounted() {
    this.initInClient()
  },
  methods: {
    initInClient() {
      this.initTrialInfo()
      
      if(this.isPrime) {
        daEventCenter.triggerNotice({
          daId: '1-22-8-1',
          extraData: this.getExposeParams(),
        })
      }
      
      this.$nextTick(() => {
        setTimeout(() => {
          this.payBackHandle()
        }, 1000)
      })
    },
    pageDataInit() {
      // 免邮项
      const freeShippingItem = this.productInfoList?.[0]?.right_config_list?.find(item => item.right_type_code === 'FREE_SHIPPING_TIMES')
      // 免邮券张数
      this.curUserCouponCount = freeShippingItem?.coupon_code_list?.reduce((total, nextItem) =>{
        return total += (nextItem?.total_sheets || 0)
      }, 0)
      // 免邮券价值
      this.couponValueWithSymbol = freeShippingItem?.coupon_value_with_symbol || ''
    },
    // 省额提示
    getHasSaveTxt (hasTotalDiscountPrice, roi, isExpired) {
      const {
        req_currency_price: hasSaved,
        req_currency_price_with_symbol: savedWithSymbol
      } = this.paidDetail?.billno_enjoy_privilege_info || {}
      return {
        isExpired,
        tipTitle: roi >= 1 ? this.template(roi, this.language.SHEIN_KEY_PC_29386) : '',
        subTitle: !hasSaved
          ? this.language[hasTotalDiscountPrice ? 'SHEIN_KEY_PC_29382' : 'SHEIN_KEY_PC_29383']
          : this.template(savedWithSymbol, this.language[isExpired ? 'SHEIN_KEY_PC_29381' : 'SHEIN_KEY_PC_29380'])
      }
    },
    // 续费引导文案、续费按钮
    getContentAndBtnTxt (hasTotalDiscountPrice) {
      const primeGift = this.commandProduct?.gift_info_list?.filter?.(item => item.gift_type == 1) || []
      return {
        content1: hasTotalDiscountPrice
          ? this.language.SHEIN_KEY_PC_29387
          : this.language[primeGift?.length > 0 ? 'SHEIN_KEY_PC_29389' : 'SHEIN_KEY_PC_29391'],
        content2: hasTotalDiscountPrice
          ? this.template(this.totalDiscountPriceWithSymbol, this.language.SHEIN_KEY_PC_29388)
          : this.language[primeGift?.length > 0 ? 'SHEIN_KEY_PC_29390' : 'SHEIN_KEY_PC_29392'],
        btn: this.template(
          `<span class="coupon-price">${this.arrivalPriceWithSymbol}</span>`,
          this.language[hasTotalDiscountPrice ? 'SHEIN_KEY_PC_29384' : 'SHEIN_KEY_PC_29385'],
        )
      }
    },
    getSpanTxt(text) {
      return `<span style="word-break: normal;">${text}</span>`
    },
    getSpanTxtBigSize(text) {
      return `<span style="word-break: normal;color: #873C00;">${text}</span>`
    },
    template,
    renewHandle () {
      daEventCenter.triggerNotice({
        daId: '1-22-8-2',
        extraData: this.getExposeParams(),
      })
      location.href = '/user/prime?productsource=me_popup&renew=1'
      return
    },
    getExposeParams() {
      const data = {
        prime_level: 0,
        total_saving: 0,
        prime_promotion: 0,
        expiring_flag: 0,
        renew_flag: 0,
        prime_tp: 0,
        prime_days: 0
      }
      if (this.isPrime) {
        let { discount_price_with_symbol } = this.isSaveAmount || {}
        data.prime_level = this.paidDetail?.product_info?.product_cycle_type || 0
        data.total_saving = this.isOverZeroAmount ? discount_price_with_symbol : 0
        data.prime_tp = this.paidDetail?.product_info?.product_type
        data.prime_days = this.paidDetail?.product_info?.product_cycle_days
      } else {
        data.prime_days = this.commandProduct?.product_cycle_days
        data.prime_tp = this.commandProduct?.product_type
      }
      if (this.trialInfo?.can_trial == 1) {
        data.prime_tp = 1
        data.prime_days = this.productInfoList.find(val => val.product_type == 1)?.product_cycle_days || ''
      }
      if (this.buyWhen == 2) data.prime_level = -1
      if (this.isDiscountByRenew) data.prime_promotion = 1
      data.expiring_flag = this.buyWhen == 3 ? 1 : !this.isPrime ? '-' : 0
      if ([2, 3].includes(this.buyWhen)) data.renew_flag = 1
      // 2012-12-18 新增埋点buy_when button_content
      data.buy_when = this.buyWhen
      data.button_content = this.getButtonContent
      return data
    },
    // 是否弹出回本弹窗
    payBackHandle () {
      // 续费弹窗优先级高于回本弹窗
      this.isShowBackDialog = true
    },
    async initTrialInfo () {
      if ([2, 3].includes(this.buyWhen)) {
        if (this.buyWhen == 2) {
          // 是否过期超过365天
          await this.isOverExpireDate()
        }
        // 命中阿波罗配置展示续费
        if (this.productInfoList.some(_ => this.sheinclubDiscountType.includes(_?.product_price_info?.promotion_type))) {
          this.trialInfo = {}
          this.isTrialPrime = false
        }
      } else {
        await this.getIsTrialUser()
      }
    },
    /**
     * 是否过期超过365天
     * @是 是否可以参与试用 ===> @是 展示试用引导按钮 @否 展示续费引导按钮
     * @否 按照续费引导按钮
     */
    async isOverExpireDate () {
      const delay = Math.round(new Date().getTime() / 1000)
      const maxExpireDate = 365 * 24 * 60 * 60
      if (delay - this.paidDetail?.product_info?.total_end_time <= maxExpireDate) return
      await this.getIsTrialUser()
    },
    getProductCode (list) {
      let code = ''
      // 获取试用版本产品列表
      const trialList = list.filter(val => val.product_type == 1)
      if (trialList.length) {
        // 试用推荐产品
        trialList.forEach(val => {
          if (val.is_recommend == '1') {
            code = val?.product_code || ''
          }
        })
        // 无推荐取第一个
        if (!code) {
          code = trialList?.[0]?.product_code || ''
        }
      }
      return code
    },
    // 是否有试用资格
    async getIsTrialUser () {
      if (!this.productInfoList.some(_ => _.product_type == 1)) return
      if (this.isPrime) return
      if (this.abtInfo?.sheinclubfreetrial?.param != 'exposure_freetrial_sheinclub') return
      let trialInfo = { can_trial: 1 }
      const trialCode = this.getProductCode(this.productInfoList)
      if (trialCode) {
        const res = await this.postCheckFreeTrial({
          product_code: trialCode
        })
        trialInfo = res?.info || {}
        if (res?.info?.can_trial) {
          // 有试用资格清除缓存
          removeLocalStorage('prime_cache')
        }
      }
      this.trialInfo = trialInfo
      this.isTrialPrime = this.trialInfo?.can_trial == 1
    },
    // 获取试用资格
    async postCheckFreeTrial (data) {
      const res = await postCheckFreeTrial(data)
      return res
    },
    // 获取促销信息
    getScidMallCode() {
      let { promotion_id = '' } =
        this.paidDetail?.product_info?.right_config_list?.[0]
          ?.promotion_info_list?.[0] || {}
      return new Promise((resolve) => {
        schttp({
          method: 'POST',
          url: `/api/prime/postScIdMallCode/get`,
          data: { promotionId: promotion_id },
        }).then((res) => {
          resolve(res)
        })
      })
    },
    // 点击购买折扣商品
    async goShopping() {
      daEventCenter.triggerNotice({
        daId: '1-22-8-3',
        extraData: this.getExposeParams(),
      })
      let { scId, mall_code } = await this.getScidMallCode()
      location.href = `${this.langPath}/prime-sc-${scId}.html?mallCode=${mall_code}&lockMall=1&productsource=me`
      return
    },
    // 跳转到付费会员落地页
    jumpTo () {
      daEventCenter.triggerNotice({
        daId: '1-22-8-2',
        extraData: this.getExposeParams(),
      })
      let query = 'productsource=me'
      if (this.isTrialPrime) {
        query += '&trial=1'
      }
      location.href = `${this.langPath}/user/prime?${query}`
      return
    },
    handleIsShowRenewDialog() {
      // near_expired_rules:临期配置 overdue_rules: 逾期配置 
      const { overdue_rules = [], near_expired_rules = [] } = this.RENEW_DIALOG_DELAY
      // 1. abt 判断续费弹窗abt是否开启 已经登录 buy_when 不为空
      const abtBranch = this.abtInfo?.ClubRenewMePop?.param?.ClubRenewMePop == 'on'
      const result = abtBranch && this.buyWhen && this.primeInfoThreeForOne?.reason !== 5
      if(!result) {
        return false
      }

      // 插入场景
      // 当以下条件同时满足时，不弹付费会员续费引导弹窗：
      // ① 无省额
      // /v2/User/prime/paidDetail接口的req_currency_price字段 < 0
      // ② 无会费价&会费券
      // /v2/User/prime/queryMemberBuyProduct接口的total_discount_price < 0
      // ③ 无买赠活动
      // /v2/User/prime/queryMemberBuyProduct接口的gift_type≠1
      const req_currency_price = this.paidDetail?.billno_enjoy_privilege_info?.req_currency_price || 0
      const total_discount_price = this.commandProduct?.product_price_info?.total_discount_price || 0
      const flag1 = req_currency_price <= 0
      const flag2 = total_discount_price <= 0
      let flag3
      const currentGift = this.commandProduct?.gift_info_list?.[0] || {}
      if(currentGift?.gift_type != 1) {
        flag3 = true
      }
      if(flag1 && flag2 && flag3) {
        return false
      }

      // 2. 判断是临期还是逾期，
      // 若差值≥0，则为临期状态，将差值换算为临期天数
      // 若临期天数为0~3天，用户每天首次访问个人中心，均弹出1次
      // 若临期天数为4~30天，用户每间隔5天 首次访问个人中心，弹出1次
      // 若差值<0，则为逾期状态，将差值换算为逾期天数
      // 若逾期天数为0~7天，用户每间隔2天 首次访问个人中心，弹出1次
      // 若逾期天数为8~21天，用户每间隔5天 首次访问个人中心，弹出1次
      // 若逾期天数为22~60天，用户每间隔10天 首次访问个人中心，弹出1次
      const endTime = this.paidDetail?.product_info?.total_end_time * 1000
      const currentTime = new Date().getTime()
      
      const time = endTime - currentTime
      let days = time / 1000 / 60 / 60 / 24
      let flag, currentRule
      if(days >= 0) {
        currentRule = near_expired_rules.find(rule => days >= rule.left_bound && days < rule.right_bound)
      } else {
        days = -days
        currentRule = overdue_rules.find(rule => days >= rule.left_bound && days < rule.right_bound)
      }
      if(currentRule) {
        flag = this.timeCheck(currentRule.interval_time * 1000, currentTime,  time >= 0 ? 'nearExpired' : 'expired')
      } else {
        flag = false
      }
      return flag
    },
    // 校验缓存时间是否失效
    timeCheck(time, currentTime, type) {
      const cacheKey = (this.SiteUID || '') + '-' + (this.userInfo?.member_id || '') + '-'
      // 临期key
      const nearExpiredKey = cacheKey + 'primeV2RenewDialog'
      // 逾期key
      const ExpiredKey = cacheKey + 'primeV2ExpiredRenewDialog'

      let cacheTimeKey
      if(type == 'nearExpired') {
        cacheTimeKey = nearExpiredKey
      } else {
        cacheTimeKey = ExpiredKey
      }
      const cacheTime = +getLocalStorage(cacheTimeKey)

      if(!cacheTime) { // 不存在缓存，触发弹窗
        return true
      } else {
        // 先判断缓存是否失效，如果未失效，不触发弹窗,返回false
        // 如果缓存失效,触发弹窗，存储当前时间，返回true

        // 当前时间 2024年10月12日 10：20:30
        // 0天失效，2024年10月13日 10:20:30
        // 24 小时/天 × 60 分钟/小时 × 60 秒/分钟 × 1000 毫秒/秒
        // 缓存的开始时间
        const cacheDayStartTime = new Date(cacheTime).getTime()
        // 当天的开始时间
        const currentDayStartTime = new Date(currentTime).getTime()
        // 命中缓存
        if(currentDayStartTime - cacheDayStartTime < time) {
          return false
        } else {
          return true
        }
      }
    },
  },
}
</script>

<style lang="less">
.prime-pannel_wrapper {
  margin-top: 20px;
  border-radius: 3px;
  flex-grow: 1;
}
.prime-pannel {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 3px;
  // overflow: hidden;
  cursor: pointer;
  .pannel-main {
    border-radius: 3px;
    background: linear-gradient(
      272.5deg,
      rgba(255, 186, 151, 0.4) 4.35%,
      rgba(255, 186, 151, 0.192) 100%
    );
  }
  .prime-hd {
    width: 100%;
    height: 44px;
    border-radius: 3px 3px 0 0;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .prime-hd_img {
      height: 20px;
      // .margin-l(14px);
    }
    &__left {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg, rgba(255, 204, 178, 0.25) 0%, rgba(255, 175, 134, 0.25) 100%);
      // background: linear-gradient(180deg, rgba(255, 204, 178, 0.25) 0%, rgba(255, 175, 134, 0.25) 100%);
      height: 100%;
      border-radius: 0px 0px 12px 0px;
      padding: 0 8px;
    }
    &__expire {
      background: #E5E5E5;
      border: 1px solid rgba(187, 187, 187, 0.5);
      border-radius: 21px;
      padding: 3px 6px;
      color: #767676;
      font-size: 12px;
      .margin-l(4px);
      transform: scale(.8333);
    }
    &__trial {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      padding: 2px 6px;
      background: rgba(255, 186, 151, 0.3);
      border: 1px solid rgba(135, 60, 0, 0.2);
      border-radius: 21px;
      color: rgba(135, 60, 0, .8);
      transform: scale(.8333);
    }
  }
  .prime-hd_right {
    max-width: 60%;
    text-align: right;
    cursor: pointer;
    color: rgba(159, 79, 45, 1);
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    .padding-r(12px);
    .join-btn {
      position: relative;
      padding: 6px 12px;
      background: linear-gradient(270deg, #ffd3bc 0%, #ffe2d3 100%);
      border: 1px solid rgba(196, 74, 1, 0.5);
      border-radius: 81px;
      color: #c44a01;
      font-weight: 700;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .trial-price {
      color: #DA7E50;
      font-size: 12px;
      font-weight: 400;
      text-decoration: line-through;
    }
  }
  .prime-content {
    width: 100%;
    padding: 16px 8px;
    font-size: 14px;
    color: rgba(159, 79, 45, 0.8);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 0 0 3px 3px;
  }
  .right-config-list {
    background: linear-gradient(180deg, rgba(255, 186, 151, 0.33) 0%, rgba(255, 186, 151, 0.00) 100%);
    padding: 12px;
  }
  .equity-item {
    flex: 1;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      .margin-r(8px);
    }
  }
  .more-item {
    // flex-direction: column;
    text-align: center;
    padding: 0 8px;
    img {
      .margin-r(4px);
      // margin-bottom: 6px;
    }
  }
  .equity-item_line {
    width: 1px;
    height: 24px;
    background-color: rgba(159, 79, 45, 0.15);
    &:last-child {
      width: 0;
    }
  }
  .more-equity {
    text-align: center;
    cursor: pointer;
    margin: 0 8px;
  }
  .use-prime {
    padding: 16px;
    justify-content: space-between;
    p {
      font-size: 14px;
      display: flex;
      align-items: center;
    }
    .use-prime_amount {
      font-size: 24px;
      font-weight: 700;
      color: #873c00;
      .margin-l(2px);
    }
  }
  .start-shipping {
    min-width: 114px;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    padding: 6px 12px;
    color: #873c00;
    border: 1px solid rgba(135, 60, 0, 0.5);
    border-radius: 20px;
    .margin-l(16px);
  }
}
.prime__renew-wrapper {
  font-size: 14px;
  line-height: 17px;
  padding: 16px;
  background-color: #FFF7F2;
  color: rgba(201, 110, 63, .6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 3px 3px;
  span {
    font-weight: 700;
    color: @sui_color_discount;
  }
  .renew-btn {
    position: relative;
    padding: 5px 8px;
    border: 1px solid #C44A01;
    border-radius: 20px;
    .margin-l(20px);
    white-space: nowrap;
    cursor: pointer;
    span {
      font-weight: 400;
      color: #C44A01;
    }
  }
}
.prime__renew-trial {
  background: rgba(255, 241, 213, 0.6);
  color: #C96E3F;
}

.renew-expire__pannel {
  font-size: 14px;
  margin: 0 16px;
  padding: 16px 0;
  color: #767676;
  border-bottom: 1px solid rgba(135, 60, 0, .1);
  span {
    font-weight: 700;
    color: @sui_color_discount;
  }
}
.prime-tips {
  font-size: 14px;
  line-height: 17px;
  padding: 16px 0;
  margin: 0 16px;
  color: #873c00;
  border-bottom: 1px solid rgba(135, 60, 0, .1);
  span {
    font-size: 14px;
    font-weight: 700;
    color: #FA6338;
  }
}


.prime-pannel2 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 274px;
  border-radius: 6px;
  overflow: hidden;
  background: linear-gradient(270deg, rgba(255, 186, 151, 0.40) 0%, rgba(255, 186, 151, 0.19) 100%);
  cursor: pointer;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 274px;
    height: 116px;
    background: url('/she_dist/images/prime2-bg-88beb5e6f1.png') no-repeat;
    background-size: 274px 116px;
    pointer-events: none; /* 让事件透传 */
  }
  &__ar::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 274px;
    height: 116px;
    background: url('/she_dist/images/prime2-ar-bg-d7a6ce35a4.png') no-repeat;
    background-size: 274px 116px;
    pointer-events: none; /* 让事件透传 */
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo-wrap {
    display: inline-flex;
    position: relative;
    z-index: 2;
    white-space: nowrap;
    height: 34px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 12px 0px;
    background: linear-gradient(180deg, rgba(255, 204, 178, 0.25) 0%, rgba(255, 175, 134, 0.25) 100%);
    .logo {
      height: 20px;
    }
    .status {
      display: flex;
      padding: 2px 6px;
      margin-left: 6px;
      align-items: center;
      border-radius: 20px;
      border: 1px solid #D0D0D0;
      background: #E5E5E5;
      color: #767676;
      font-family: SF UI Text;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .free {
      display: flex;
      padding: 2px 6px;
      margin-left: 6px;
      align-items: center;
      border-radius: 28px;
      background: rgba(255, 186, 151, 0.3);
      border: 1px solid rgba(135, 60, 0, 0.2);
      color: rgba(135,60,0,.8);
    }
  }
  &__icons {
    position: absolute;
    right: 4px;
    z-index: 3;
    display: flex;
  }
  &__icon-wrap {
    margin-left: 2px;
    img{
      width: 24px;
      height: 24px;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    min-height: 72px;
  }
  &__text {
    color: #873C00;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: #FA6338;
      font-weight: bold;
    }
    .amount {
      color: #873C00;
      font-size: 24px;
      font-weight: bold;
    }
  }
  &__footer {
    flex: 1;
    display: flex;
    width: 274px;
    min-height: 60px;
    padding: 4px 12px;
    align-items: center;
    background: var(--sui-color-club-rosegold-light-2, #FFE2D3);
  }
}

</style>
