<template>
  <UserCenterContainer
    v-if="context.useBffApi"
    :context="context"
  />
  <App
    v-else
    :context="context"
  />
</template>

<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import App from './index.vue'

export default defineComponent({
  name: 'UserCenter',
  components: {
    UserCenterContainer: defineAsyncComponent(() => import('./index-new.vue')),
    App,
  },
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>
